import React, { useState } from 'react';
import './css/cambios.css';
import { useNavigate } from 'react-router-dom';
import logo from './images/LogoD.png'; // Asegúrate de ajustar la ruta al archivo de la imagen

// Definir subOptions antes de su uso
const subOptions = {
    // "Ejemplo Sintaxis": [
    //     { 
    //         name: 'Ver', 
    //         text: `<div class="pricing-table">
    //             <table style="width: 100%; max-width: 650px; border-collapse: collapse; overflow-x: auto;">
    //                 <thead>
    //                     <tr><th>Ejemplo de tabla</th></tr>
    //                 </thead>
    //                 <tbody>
    //                     <tr><td>Cuanto más FRW tengas, más desinches vas a poder hacer, sin embargo, excederse también es malo.</td></tr>
    //                 </tbody>
    //             </table>
    //         </div>` 
    //     }
    // ],

    "Breaks - FCR": [
        { 
    name: 'Breaks - FCR',
    text: `
        <div class="tabla-atributos" id="tabla-casting-frames">
        <tr><h1>Breaks - FCR</h1></tr>
            <table>
                <thead>
                
                    <tr>
                        <th>Character</th>
                        <th>Skill / Form</th>
                        <th>19</th>
                        <th>18</th>
                        <th>17</th>
                        <th>16</th>
                        <th>15</th>
                        <th>14</th>
                        <th>13</th>
                        <th>12</th>
                        <th>11</th>
                        <th>10</th>
                        <th>9</th>
                        <th>8</th>
                        <th>7</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Amazon</td>
                        <td>All</td>
                        <td>0</td>
                        <td>7</td>
                        <td>14</td>
                        <td>22</td>
                        <td>32</td>
                        <td>48</td>
                        <td>68</td>
                        <td>99</td>
                        <td>152</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Assassin</td>
                        <td>All</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>8</td>
                        <td>16</td>
                        <td>27</td>
                        <td>42</td>
                        <td>65</td>
                        <td><strong>102</strong></td>
                        <td>174</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Barbarian</td>
                        <td>All</td>
                       
                                                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>9</td>
                        <td>20</td>
                        <td><strong>37</strong></td>
                        <td><strong>63</strong></td>
                        <td>105</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Druid</td>
                    <td>Human</td>
                   
                    <td></td>
                    <td></td>
                    <td>0</td>
                    <td>4</td>
                    <td>10</td>
                    <td>19</td>
                    <td>30</td>
                    <td>46</td>
                    <td>68</td>
                    <td><strong>99</strong></td>
                    <td><strong>163</strong></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Druid</td>
                    <td>Bear form</td>
                    
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>0</td>
                    <td>7</td>
                    <td>15</td>
                    <td>26</td>
                    <td>40</td>
                    <td>63</td>
                    <td>99</td>
                    <td>163</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Druid</td>
                    <td>Wolf form</td>
                   
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>0</td>
                    <td>6</td>
                    <td>14</td>
                    <td>26</td>
                    <td>40</td>
                    <td>60</td>
                    <td>95</td>
                    <td>157</td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                        <td>Necromancer</td>
                        <td>Human</td>
                       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>9</td>
                        <td>18</td>
                        <td>30</td>
                        <td>48</td>
                        <td>75</td>
                        <td><strong>125</strong></td>

                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Sorceress</td>
                        <td>Rayo</td>
                       
                        <td>0</td>
                        <td>7</td>
                        <td>15</td>
                        <td>23</td>
                        <td>35</td>
                        <td>52</td>
                        <td>78</td>
                        <td><strong>117</strong></td>
                        <td><strong>194</strong></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                   </tr>
                   <tr>
                    <td>Sorceress</td>
                    <td>Frio/Fuego</td>
                    
                                                <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>0</td>
                    <td>9</td>
                    <td>20</td>
                    <td>37</td>
                    <td>63</td>
                    <td><strong>105</strong></td>
                    <td><strong>200</strong></td>

                </tr>
                </tbody>
            </table>
        </div>
    `,
}
],

"Breaks - FHR": [
    {
    "name": "Breaks - FHR",
    "text": `
        <div class="tabla-atributos">
         <tr><h1>Breaks - FHR</h1></tr>
            <table>
                <thead>
                    <tr>
                        <th>Character</th>
                        <th>15</th>
                        <th>14</th>
                        <th>13</th>
                        <th>12</th>
                        <th>11</th>
                        <th>10</th>
                        <th>9</th>
                        <th>8</th>
                        <th>7</th>
                        <th>6</th>
                        <th>5</th>
                        <th>4</th>
                        <th>3</th>
                        <th>2</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Amazona</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>6</td>
                        <td>13</td>
                        <td>20</td>
                        <td>32</td>
                        <td>52</td>
                        <td>86</td>
                        <td>174</td>
                        <td>600</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Asesina</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>7</td>
                        <td>15</td>
                        <td>27</td>
                        <td>48</td>
                        <td>86</td>
                        <td>200</td>
                        <td></td>
                        
                    </tr>
                    <tr>
                        <td>Barbarian</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>7</td>
                        <td>15</td>
                        <td>27</td>
                        <td>48</td>
                        <td>86</td>
                        
                        <td>200</td>
                        <td></td>
                        
                    </tr>
                    <tr>
                        <td>Druid</td>
                       
                        <td></td>
                        <td>0</td>
                        <td>3</td>
                        <td>7</td>
                        <td>13</td>
                        <td>19</td>
                        <td>29</td>
                        <td>42</td>
                        <td>63</td>
                        <td>99</td>
                        <td>174</td>
                        <td>456</td>
                        <td></td>                                
                        <td></td>                                
                    </tr>


                    <tr>
                        <td>Druid Wolf Form</td>

                                                        <td></td>
                                                        <td></td>
                        <td></td>
                                                        <td></td>
                        <td></td>
                                                        <td></td>
                        <td></td>
                                                        <td></td>
                        <td>0</td>
                        <td>9</td>
                        <td>20</td>
                        <td>42</td>
                        <td>86</td>
                        <td>280</td>
                    </tr>
                    <tr>
                        <td>Necromancer</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>5</td>
                        <td>10</td>
                        <td>16</td>
                        <td>26</td>
                        <td>39</td>
                        <td>56</td>
                        <td>86</td>
                        <td>152</td>
                        <td>377</td>
                        <td></td>
                        
                    </tr>

                    <tr>
                        <td>Paladin</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        
                        <td>0</td>
                        <td>7</td>
                        <td>15</td>
                        <td>27</td>
                        <td>48</td>
                        <td>86</td>
                        <td>200</td>
                        
                    </tr>
                    <tr>
                        <td>Sorceress</td>
                        
                        <td>0</td>
                        <td>5</td>
                        <td>9</td>
                        <td>14</td>
                        <td>20</td>
                        <td>30</td>
                        <td>42</td>
                        <td>60</td>
                        <td>86</td>
                        <td>142</td>
                        <td>280</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    `
}
],
    "Breaks - FBR": [
        {
    "name": "Breaks - FBR",
    "text": `
        <div class="tabla-atributos">
         <tr><h1>Breaks - FBR</h1></tr>
            <table>
                <thead>
                    <tr>
                        <th>Character</th>
                        <th>Weapon / Skill / Form</th>

                        <th>17</th>
                        <th>16</th>
                        <th>15</th>
                        <th>14</th>
                        <th>13</th>
                        <th>12</th>
                        <th>11</th>
                        <th>10</th>
                        <th>9</th>
                        <th>8</th>
                        <th>7</th>
                        <th>6</th>
                        <th>5</th>
                        <th>4</th>
                        <th>3</th>
                        <th>2</th>
                        <th>1</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Amazon</td>
                        <td>Armas de 1 Mano</td>

                        <td>0</td>
                        <td>4</td>
                        <td>6</td>
                        <td>11</td>
                        <td>15</td>
                        <td>23</td>
                        <td>29</td>
                        <td>40</td>
                        <td>56</td>
                        <td>80</td>
                        <td>120</td>
                        <td>200</td>
                        <td>480</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Assassin</td>
                        <td>All</td>

                        <td></td>
                                                                                       <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>13</td>
                        <td>32</td>
                        <td>86</td>
                        <td>600</td>

                    </tr>
                    <tr>
                        <td>Barbarian</td>
                        <td>All</td>

                        <td></td>
                        <td></td>
                        <td></td>
                        
                                                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>9</td>
                        <td>20</td>
                        <td>42</td>
                        <td>86</td>
                        <td>280</td>
                                                                                        <td></td>

                    </tr>
                    <tr>
                        <td>Druid</td>
                        <td>Human form</td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>6</td>
                        <td>13</td>
                        <td>20</td>
                        <td>32</td>
                        <td>52</td>
                        <td>86</td>
                        <td>174</td>
                        <td>600</td>
                                                                                        <td></td>
                        <td></td>
                        
                        
                    </tr>
                    <tr>
                        <td>Druid</td>
                        <td>Bear form</td>

                        <td></td>
                        <td></td>
                        
                                                        <td></td>
                        <td></td>

                                                                                        <td></td>

                        <td>0</td>
                        <td>5</td>
                        <td>10</td>
                        <td>16</td>
                        <td>27</td>
                        <td>40</td>
                        <td>65</td>
                        <td>109</td>
                        <td>223</td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>
                    <tr>
                        <td>Druid</td>
                        <td>Wolf form</td>

                                                       <td></td>
                        <td></td>
                        <td></td>
                                                                                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>7</td>
                        <td>15</td>
                        <td>27</td>
                        <td>48</td>
                        <td>86</td>
                        <td>200</td>
                        <td></td>
                                                                                        <td></td>

                    </tr>
                    <tr>
                        <td>Necromancer</td>
                        <td>All</td>

                        <td></td>
                        <td></td>
                        <td></td>
                             
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>0</td>
                        <td>6</td>
                        <td>13</td>
                        <td>20</td>
                        <td>32</td>
                        <td>52</td>
                        <td>86</td>
                        <td>174</td>
                        <td>600</td>
                                                                                        <td></td>
                        <td></td>
                      

                    </tr>

                        <td>Paladin</td>
                        <td>Con Holy Shield</td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                       
                                                                                        <td></td>
                        <td></td>
                                                                                        <td></td>
                        <td></td>
                                                                                        <td></td>
                        <td></td>
                                                                                        <td></td>
                        <td></td>
                                                                                        <td></td>
                        <td></td>
<td></td>
                        <td>0</td>
                        <td>86</td>
                       
                    </tr>
                    <tr>
                        <td>Sorceress</td>
                        <td>All</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                                                        <td>0</td>
                        <td>7</td>
                        <td>15</td>
                        <td>27</td>
                        <td>48</td>
                        <td>86</td>
                        <td>200</td>
                           <td></td>
                                                                                        <td></td>

                    </tr>
                </tbody>
            </table>
        </div>
    `
}


  ],
    "Breaks - IAS": [
        { 
            "name": "Breaks - IAS",
            "text": `
                <div class="tabla-atributos">                    
                    <h3>Recomendaciones de IAS:</h3>
                    <ul>
                    <br></br>
                        <li><strong>Amazon:</strong>
                        <br></br>
                            <p>GA, Multiple Shot: GMB, 15 lvl Fanaticism</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>IAS</th>
                                        <th>attack speed [ticks]</th>
                                        <th>attacks per second</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>0</td>
                                        <td>11</td>
                                        <td>2.27</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>10</td>
                                        <td>2.5</td>
                                    </tr>
                                    <tr>
                                        <td>22</td>
                                        <td>9</td>
                                        <td>2.77</td>
                                    </tr>
                                    <tr>
                                        <td>46</td>
                                        <td>8</td>
                                        <td>3.12</td>
                                    </tr>
                                    <tr>
                                        <td>92</td>
                                        <td>7</td>
                                        <td>3.57</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>Circlet (45) + Gloves (20) + HL(20) + Nosferatu's (10) = 95 (92)</p>
                            <br></br>
                            <p>Fury Bolt: Matriarchal Javelin</p>
                            <br></br>
                            <table>
                                <thead>
                                    <tr>
                                        <th>IAS</th>
                                        <th>attack speed [ticks]</th>
                                        <th>attacks per second</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>0</td>
                                        <td>14</td>
                                        <td>1.78</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>13</td>
                                        <td>1.92</td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td>12</td>
                                        <td>2.08</td>
                                    </tr>
                                    <tr>
                                        <td>30</td>
                                        <td>11</td>
                                        <td>2.27</td>
                                    </tr>
                                    <tr>
                                        <td>52</td>
                                        <td>10</td>
                                        <td>2.5</td>
                                    </tr>
                                    <tr>
                                        <td>89</td>
                                        <td>9</td>
                                        <td>2.77</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>Javelins (40) Circlet (45) + Gloves (20) + HL(20) + Nosferatu's (10) = 135 (89)</p>
                            <br></br>
                                                   </li>
                                           <li><strong>Barbarian:</strong>
                                           <br></br>
                                           <pa>BA = Zerk</pa>
                                           <br></br>
                        <p>Whirlwind: Grief BA + Beast BA, 9 lvl Fanaticism - 34+ on each BA</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>IAS</th>
                                    <th>attack speed [ticks]</th>
                                    <th>attacks per second</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>0</td>
                                    <td>12</td>
                                    <td>2.08</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>11</td>
                                    <td>2.27</td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>10</td>
                                    <td>2.5</td>
                                </tr>
                                <tr>
                                    <td>44</td>
                                    <td>9</td>
                                    <td>2.77</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Grief BA (40) + HL (20) = 60 (44)</p>
                        <br></br>
                        <p>Berserk: Grief BA + Beast BA, 9 lvl Fanaticism, Clay Golem's slow</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>IAS</th>
                                    <th>attack speed [ticks]</th>
                                    <th>attacks per second</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>52</td>
                                    <td>13</td>
                                    <td>1.92</td>
                                </tr>
                                <tr>
                                    <td>72</td>
                                    <td>12</td>
                                    <td>2.08</td>
                                </tr>
                                <tr>
                                    <td>102</td>
                                    <td>11</td>
                                    <td>2.27</td>
                                </tr>
                                <tr>
                                    <td>152</td>
                                    <td>10</td>
                                    <td>2.5</td>
                                </tr>
                                <tr>
                                    <td>259</td>
                                    <td>9</td>
                                    <td>2.77</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Si prefieres matar con Zerk, puedes intentar acumular</p>
                         <p>IAS contra la lentitud de Clay Golem para obtener 11 Ticks.</p>
                        <p>Grief BA (40) + Circlet (20 fcr, 3x 15ias/40ed)(45) + HL (20) = 105 (102)</p>
                    <br></br>
                        </li>
                    <li><strong>Paladin:</strong>
                    <br></br>
                        <p>Smite: Grief Phase Blade - 34 lvl Fanaticism</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>IAS</th>
                                    <th>attack speed [ticks]</th>
                                    <th>attacks per second</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>0</td>
                                    <td>7</td>
                                    <td>3.57</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>6</td>
                                    <td>4.16</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Podemos llegar facilmente ya que la Grief nos da 30-40 IAS.</p>
                        <br></br>
                        <p>Smite: Grief Phase Blade 40 IAS - 34 lvl Fanaticism, Clay Golem's slow</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>IAS</th>
                                    <th>attack speed [ticks]</th>
                                    <th>attacks per second</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>20</td>
                                    <td>8</td>
                                    <td>3.12</td>
                                </tr>
                                <tr>
                                    <td>46</td>
                                    <td>7</td>
                                    <td>3.57</td>
                                </tr>
                                <tr>
                                    <td>102</td>
                                    <td>6</td>
                                    <td>4.16</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Quizás quieras agregar 1 Jwl 15 ias para alcanzar los 7 Ticks.</p>
                    <br></br>
                    <p>Smite; Grief BA 40 IAS - 32/34 lvl Fanaticism</p>
<table>
    <thead>
        <tr>
            <th>IAS</th>
            <th>attack speed [ticks]</th>
            <th>attacks per second</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>15</td>
            <td>7</td>
            <td>3.57</td>
        </tr>
        <tr>
            <td>50</td>
            <td>6</td>
            <td>4.16</td>
        </tr>
    </tbody>
</table>
<p>You might wanna put a 15 IAS jewel in your Circlet (or use 20% IAS Gloves) for 6 frames.</p>
<br></br>
</div>
            `,
        }
		    ],

"Breaks - FRW": [
    { 
        "name": "Breaks - FRW",
        "text": `
            <div class="pricing-table">                    
                <h3>Recomendaciones de FRW:</h3>
                <br></br>
                <p>Cuanto más FRW tengas, más "Desinch" vas a poder hacer, sin embargo, excederse también es malo.</p>
                <br></br>
                <table>
                    <tr><th style="text-align: center; font-size: 1.3em;">Amazon</th></tr>
                    <tr><td>Circlet (30) + WT (30) + SC's (21+) = 125+ &lt;Mínimo Recomendado</td></tr>
                    <tr><td>Circlet (30) + WT (30) + SC's (33) = 159 &lt;Máximo, full inventario de 3frw/20ar/20hp</td></tr>
                    <tr><th style="text-align: center; font-size: 1.3em;">Assassin</th></tr>
                    <tr><td>Enigma (45) + SD (30) + BoS (18+) = 135 + BoS</td></tr>
                    <tr><td>Enigma (45) + SD (30) + Circlet (30) + BoS (18+) = 165+ &lt;Es bueno excederse por si un Golem te relentiza y a su vez puedes evadir Espíritus/Lanzas.</td></tr>
                    <tr><th style="text-align: center; font-size: 1.3em;">Barbarian</th></tr>
                    <tr><td>Enigma (45) + Gore's (30) + Increased Speed (42+) = 117+</td></tr>
                    <tr><td>Enigma (45) + Gore's (30) + Circlet (30) + Increased Speed (42+) = 147+ &lt;Es bueno excederse por si un Golem te relentiza y a su vez puedes evadir Espíritus/Lanzas.</td></tr>
                    <tr><th style="text-align: center; font-size: 1.3em;">Druid</th></tr>
                    <tr><td>Enigma (45) + Boots (30) + Circlet (30) = 105</td></tr>
                    <tr><th style="text-align: center; font-size: 1.3em;">Necromancer</th></tr>
                    <tr><td>Enigma (45) + Boots (30) + Circlet (30) = 105</td></tr>
                    <tr><th style="text-align: center; font-size: 1.3em;">Paladin</th></tr>
                    <tr><td>Enigma (45) + WW (20) + Vigor = 65 + Vigor (no permitido en el Server)</td></tr>
                    <tr><td>Enigma (45) + Boots (30) + Circlet (30) = 95 + Vigor &lt;Es bueno excederse por si un Golem te relentiza y a su vez puedes evadir Espíritus/Lanzas.</td></tr>
                    <tr><td>(No permitido en el Server)</td></tr>
                    <tr><th style="text-align: center; font-size: 1.3em;">Sorceress</th></tr>
                    <tr><td>Fire: Enigma (45) + Boots (30) + Circlet (30) = 105</td></tr>
                    <tr><td>Cold: Enigma (45) + Boots (30) + Circlet (30) = 105</td></tr>
                    <tr><td>Lightning: Enigma (45) + Boots (30) + Circlet (30) = 105</td></tr>
                </table>
            </div>
        `
    }
]



    
};

const Breakpoints = () => {
    // Estado para el texto seleccionado (por defecto se carga el contenido de Ver2)
    const [selectedText, setSelectedText] = useState(subOptions["Breaks - FCR"][0].text); // Predeterminado es Ver2
    const navigate = useNavigate();

    // Manejador para cambiar el contenido cuando se hace clic
    const handleOptionClick = (text) => {
        setSelectedText(text);
    };

        // Manejador para ir a la página de cambios
        const handleChangesNavigation = () => {
            navigate('/cambios');
        };

    // Manejador para ir a la página de donaciones
    const handleNavigation = () => {
        navigate('/donaciones');
    };

    return (
        <div className="subweb-container">
            <div className="subweb-navbar">
                <div 
                    className="subweb-logo" 
                    onClick={() => window.location.href = '/'}
                >
                    <img src={logo} alt="Logo" className="subweb-logo-img" />
                </div>



                {/* Opciones del menú */}
                {Object.keys(subOptions).map((option) => (
                    <div 
                        key={option} 
                        className="subweb-nav-option"
                        onClick={() => handleOptionClick(subOptions[option][0].text)} // Mostrar directamente el contenido de la primera subopción
                    >
                        {option}
                    </div>
                ))}


{/* Opción para cambios */}
<div 
    className="subweb-nav-option" 
    onClick={handleChangesNavigation}
>
    Cambios
</div>

{/* Opción para donaciones */}
<div 
    className="subweb-nav-option" 
    onClick={handleNavigation}
>
    Donaciones
</div>

                
                </div> 
                
        <div 
                id="subweb-text-display" 
                className="subweb-text-display" 
                dangerouslySetInnerHTML={{ __html: selectedText }} 
            />
        </div>
    );
};

export default Breakpoints;
