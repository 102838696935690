// DonacionesLayout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './css/breakpoints.css';

const BreakpointsLayout = ({ children }) => {
  return (
    <div className="breakpoints-layout">
      <Header />
      <div className="breakpoints-container">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default BreakpointsLayout;
