import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import GeneralLayout from './GeneralLayout';
import DonacionesLayout from './DonacionesLayout';
import Content from './Content';
import ImageSwitcher from './Image-Switcher';
import video from './videos/video.mp4';
import Donaciones from './Donaciones';
import Breakpoints from './Breakpoints';
import BreakpointsLayout from './BreakpointsLayout';
import Auras from './Auras';
import Cambios from './Cambios';
import CambiosLayout from './CambiosLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookF, faYoutube, faDiscord } from '@fortawesome/free-brands-svg-icons';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <GeneralLayout>
            <h1 className="header-title" style={{ 
              position: 'absolute', 
              top: 55, 
              left: 30, 
              zIndex: 999, 
              backgroundColor: '#b39b32', 
              fontSize: '3.5em', 
              fontFamily: 'Staatliches, cursive', 
              textTransform: 'uppercase', 
              fontWeight: 700, 
              letterSpacing: '5px', 
              textDecoration: 'none', 
              backgroundImage: 'linear-gradient( -75deg, transparent 0, transparent 5%, rgba(255,255,255,0.5) 5%, rgba(255,255,255,0.5) 10%, transparent 10%, transparent 100% )', 
              backgroundSize: '200% 100%', 
              backgroundClip: 'text', 
              WebkitBackgroundClip: 'text', 
              transition: '1.2s all',
              color: '#b39b32', // Color por defecto
              ...(window.innerWidth <= 768 && { color: 'white' }) // Cambiar a blanco solo en pantallas menores o iguales a 768px
            }}>
              DubaiServer Diablo II
            </h1>

            <video src={video} autoPlay muted loop playsInline type="video/mp4" style={{ backgroundColor: '#111' }}></video>

            <Content />
            <ImageSwitcher />
            <ul className="social-logo">
              <li>
                <a href="http://tiny.cc/WhatsappDubai" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/groups/dubaiserver" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
              <li>
                <a href="http://tiny.cc/DiscordDubai" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faDiscord} />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@DubaiServerDiabloII/videos" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </li>
            </ul>
          </GeneralLayout>
        } />
        <Route path="/donaciones" element={<DonacionesLayout><Donaciones /></DonacionesLayout>} />
        <Route path="/donaciones/auras" element={<DonacionesLayout><Auras /></DonacionesLayout>} />
        <Route path="/cambios" element={<CambiosLayout><Cambios /></CambiosLayout>} />
        <Route path="/unicos" element={<Navigate to="/cambios" replace />} />
        <Route path="/runas" element={<Navigate to="/cambios" replace />} />
        <Route path="/breakpoints" element={<BreakpointsLayout><Breakpoints /></BreakpointsLayout>} />
      </Routes>
    </Router>
  );
};

export default App;
