import React, { useState } from 'react';
import './css/cambios.css';
import { useNavigate } from 'react-router-dom';
import logo from './images/LogoD.png'; // Asegúrate de ajustar la ruta al archivo de la imagen
import CofreVid from './videos/Cofre.mp4';
import DowngVid from './videos/Downg.mp4';
import GuardarVid from './videos/Guardar.mp4';
import JoanVid from './videos/JoanVid.mp4';
import NewRibbon from './images/NewRibbon.png';

const Cambios = () => {
    const [selectedText, setSelectedText] = useState('Seleccione una opción, los nombres con * contienen los cambios de la Tercera Temporada');;
    const [dropdowns, setDropdowns] = useState({
        "Cambios Generales": false,
        "Cofre De Items": false,
        "Bolsa de Runas": false,
    });

    const navigate = useNavigate();

    const handleOptionClick = (option) => {
        if (option === "Ir a Breakpoints") {
            navigate('/breakpoints');
        } else if (option === "Ir a Highlights") {  // Añadido para Highlights
            window.location.href = 'https://www.youtube.com/@DubaiServerDiabloII/videos';  // Redirige a YouTube
        } else if (option === "Ir a Donaciones") {
            navigate('/donaciones');    
        } else {
            setSelectedText(option);
        }
    };

    // document.addEventListener('DOMContentLoaded', () => {
    //     let lastScrollTop = 0;
    //     const navbar = document.querySelector('.subweb-navbar');
      
    //     // Función para manejar el desplazamiento
    //     const handleScroll = () => {
    //       const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      
    //       if (currentScrollTop > lastScrollTop) {
    //         // Desplazamiento hacia abajo
    //         navbar.classList.remove('visible');
    //         navbar.classList.add('hidden');
    //       } else {
    //         // Desplazamiento hacia arriba
    //         navbar.classList.remove('hidden');
    //         navbar.classList.add('visible');
    //       }
      
    //       lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // Para evitar valores negativos
    //     };
      
    //     // Evento de desplazamiento
    //     window.addEventListener('scroll', handleScroll);
      
    //     // Ocultar el navbar al seleccionar una opción (solo para dispositivos móviles)
    //     document.querySelectorAll('.subweb-nav-option').forEach(option => {
    //       option.addEventListener('click', () => {
    //         if (window.innerWidth <= 767) {
    //           navbar.classList.add('hidden');
    //         }
    //       });
    //     });
    //   });



    const subOptions = {
        "*Cambios*": [
            // { 
            //     name: '*Temporada 3*',
            //     text: `<div class="pricing-table">
            //                 <table>
            //                     <thead>
            //                         <tr>
            //                             <th style="text-align: center; font-size: 1.3em;">Lista de Mejoras Sumadas a las de la Temporada 2:</th>
            //                         </tr>
            //                     </thead>
            //                     <tbody>
            //                     <tr><td style="font-size: 1.3em;">Búsca en el menú de la web los * para ver detalles de los nuevos cambios</td></tr>
            //                         <tr><td style="font-weight: bold;">Cambios en Personajes:</td></tr>
            //                         <tr><td>Buff a la Hechicera de Rayos. (Se búsca agregar variedad).<img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
            //                         <tr><td>Buff a la Asesina de Fuego. (Se búsca agregar variedad).</td></tr>
            //                         <tr><td>Nerf al Bloqueo de Garras. (Se búsca nivelar aguante vs no melee).</td></tr>
            //                         <tr><td>Nerf al Paladín. (Se búsca que su debilidad sean los melees).</td></tr>
            //                         <tr><td style="font-weight: bold;">Cambios Generales:</td></tr>
            //                         <tr><td>Formula 2 y 3 Socket a Cascos Amarillos y Azules.</td></tr>
            //                         <tr><td>Reajuste de Puntos en el Cofre de Items Únicos.</td></tr>
            //                         <tr><td>Se restaura las variaciones originales de Chaco, Oak y Enigma.</td></tr>
            //                         <tr><td>Se mejoran las probabilidades de obtener Amuletos y Cascos Raros útiles.</td></tr>
            //                         <tr><td>Mejora en Amu Craft, ahora 99% de las veces el Amuleto tiene Casteo aletorio, se mejora la probabilidad de skill útiles.</td></tr>
            //                         <tr><td>Ahora se puede donar para cambiar el skill de Tiaras.</td></tr>
            //                         <tr><td>Full Trang-oul ya no convierte por lo tanto tiene mas casteo.</td></tr>
            //                         <tr><td>Daño en área para los Personajes Melee.</td></tr>
            //                         <tr><td style="font-size: 1.3em;">Premio Nivel 99: 5 Gc Skill 50 HP (solo se consiguen con 50 hp al llegar a 99) y PJ a elección nivel 94.</td></tr>
            //                     </tbody>
            //                 </table>
            //             </div>` 
            // },
            { 
                name: '*Temporada 3*',
                text: `<div class="pricing-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th style="text-align: center; font-size: 1.3em;">Lista de Mejoras:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr><td style="font-size: 1.3em;">Búsca en el menú de la web los * y los <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/> para ver detalles de los nuevos cambios.</td></tr>
                                    <tr><td style="font-weight: bold;">Cambios en Personajes: <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>El oro se levanta automáticamente al pasar por encima (pickit). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Telekinesis ahora Stunea al Enemigo y Levanta todos los Items. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Buff a la Hechicera de Rayos. (Se búsca agregar variedad). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Buff a la Asesina de Fuego. También tiene item mejorado: Garfas del Lagarto. (Se búsca agregar variedad). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Nerf al Bloqueo de Garras. (Se búsca nivelar aguante vs no melee). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Nerf al Paladín. (Se búsca que su debilidad sean los melees). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Buff al Barbaro. Frenesí ahora obtiene 40% de su daño como mágico. (Se búsca agregar variedad). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Puedes matar al Cow King ilimitado, hay Auto pick del Oro y puedes levantar todo con Telekinesis. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Nerf al Cta. (Se elimina el repostar vida para acelerar los duelos). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td style="font-weight: bold;">Cambios Generales: <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Se mejora el Drop en Torres 3 y Trono. (igual o mejor a DH). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Formula 2 y 3 Socket a Cascos Amarillos y Azules. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Reajuste de Puntos en el Cofre de Items Únicos. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Se restaura las variaciones originales de Chaco, Oak y Enigma. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Se mejoran las probabilidades de obtener Amuletos, Cascos, Anillos,Guantes, Botas, Cintos Raros/Craft útiles. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Mejora en Amu Craft, Anillos,Botas,Cintos,Guantes, ahora 99% de las veces el Amuleto tiene Casteo aletorio, se mejora la probabilidad de skill útiles.<img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Ahora se puede donar para cambiar el skill y clase de Tiaras y Amuletos. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Se mejora la Garra Única: Garfas Del Lagarto De Fuego Ahora dan 3 Estela de Fuego y 20 Vel. Ataque. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Full Set Trang-oul ya no convierte por lo tanto tiene mas casteo. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Mercenario del Acto V es increiblemente mejorado y veloz. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Daño en área para los Personajes Melee. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>El Cta ya no reposta vida (se busca acelerar los duelos y disminuir el campeo). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Exclusivo sistema de Items por Puntos (Cofre de Items). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Exclusivo sistema de Downgrade de Runas hasta UM. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Exclusivo sistema de Carga de Gemas.</td></tr>
                                    <tr><td>Exclusivo sistema de Carga de Runas (Ral, Thul, etc.).</td></tr>
                                    <tr><td>Experiencia aumentada hasta nivel 60.</td></tr>
                                    <tr><td>Nuevo parche FULL HD 60 FPS.</td></tr>
                                    <tr><td>Nuevas Auras de Donacion.</td></tr>
                                    <tr><td>Bolsa de Gemas, Llaves, Joyas, Runas.</td></tr>
                                    <tr><td>Todos los Sets dan Teleport.</td></tr>
                                    <tr><td>El Skill Puño de los Cielos ahora hace daño a todos los enemigos.</td></tr>
                                    <tr><td>Todos los Sets otorgan Skill Teleport. El Druida puede usar Teleport siendo Lobo u Oso.</td></tr>
                                    <tr><td>Cascos de Druida y Baba otorgan entre 4-5% de experiencia aumentada.</td></tr>
                                    <tr><td>Nueva forma de Rollear Items.</td></tr>
                                    <tr><td>Logro de Items con Importantes premios</td></tr>
                                    <tr><td>Drop de Runas e Items aumentado en un 15-20%.</td></tr>
                                    <tr><td>El baúl se agranda aún más. El inventario ahora cuenta con Charm Zone.</td></tr>
                                    <tr><td>Nuevas palabras rúnicas (originales del Resurrected).</td></tr>
                                    <tr><td>Nuevas recetas del cubo horádrico (utiliza //charms en el chat).</td></tr>
                                    <tr><td>Se agregan nuevas áreas TC 85.</td></tr>
                                    <tr><td>Refresh de interfaz visual similar al Resurrected.</td></tr>
                                    <tr><td>Nuevos cambios de equilibrio a las clases.</td></tr>
                                    <tr><td>Lower Kurast nerfeado (no dropea runas).</td></tr>
                                    <tr><td>Premio Nivel 99: 9 Gc Skill 50 HP con TU nombre. (solo se consiguen con 50 hp al llegar a 99) y PJ a elección nivel 94. El primero en llegar de cada clase recibe 9 el resto 5.</td></tr>
                                </tbody>
                            </table>
                        </div>` 
            },
            { 
                name: '*Áreas 85*', 
                text: `<div class="pricing-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th style="text-align: center; font-size: 1.3em;">Áreas 85</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>Acto I: Pasadizo Subterráneo nivel 2</td></tr>
                                    <tr><td>Acto II: Tumba Pedregosa nivel 1</td></tr>
                                    <tr><td>Acto II: Tumba Pedregosa nivel 2</td></tr>
                                    <tr><td>Acto III: Cubil Arácnido</td></tr>
                                    <tr><td>Acto III: Foso Pantanoso nivel 1</td></tr>
                                    <tr><td>Acto III: Foso Pantanoso nivel 2</td></tr>
                                    <tr><td>Acto III: Foso Pantanoso nivel 3</td></tr>
                                    <tr><td>Acto III: Fano Abandonado</td></tr>
                                    <tr><td>Acto III: Templo en Ruinas</td></tr>
                                    <tr><td>Acto III: Relicario Olvidado</td></tr>
                                    <tr><td>Acto III: Cloacas nivel 1</td></tr>
                                    <tr><td>Acto V: Abaddon</td></tr>
                                    <tr><td>Acto V: Foso de Acheron</td></tr>
                                    <tr><td>Acto V: Foso Infernal</td></tr>
                                    <tr><td>Acto V: Caverna del Errante</td></tr>
                                    <tr><td>Acto V: Sótano Helado</td></tr>
                                    <tr><td>Acto V: Torres 3 y Trono de Baal MUY mejorados <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                </tbody>
                            </table>
                        </div>` 
            },
            { 
                name: 'Bonus Sets', 
                text: `<div class="pricing-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th style="text-align: center; font-size: 1.3em;">Bonus Sets:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr><td><strong>TODOS los sets del Juego otorgan 1 Teleport</strong></td></tr>
                                <tr><td><strong>Trang-oul</strong></td></tr>
                                    <tr><td>Ya no te convierte por lo que obtienes mas casteo.</td></tr>
                                    <tr><td><strong>Arcanna:</strong></td></tr>
                                    <tr><td>Pasa de +25 p. de maná a +50 p. de maná (2 objetos).</td></tr>
                                    <tr><td>+12 % de regeneración de maná (3 objetos).</td></tr>
                                    <tr><td>+1 p. a todas las habilidades (conjunto completo).</td></tr>
                                    <tr><td><strong>Cathan:</strong></td></tr>
                                    <tr><td>+16 % de regeneración de maná (2 objetos).</td></tr>
                                    <tr><td><strong>Infernal:</strong></td></tr>
                                    <tr><td>+20 % de maná máximo (conjunto completo).</td></tr>
                                    <tr><td>Se ha añadido «No te pueden congelar» (conjunto completo).</td></tr>
                                    <tr><td><strong>Naj:</strong></td></tr>
                                    <tr><td>+1-148 % de probabilidad de encontrar objetos mágicos (+1,5% por nivel del personaje) (2 objetos).</td></tr>
                                    <tr><td>+10 p. de reg HP a +20 (conjunto completo).</td></tr>
                                    <tr><td>+2 a Skill de fuego (conjunto completo).</td></tr>
                                    <tr><td>+12 % de vida máxima (conjunto completo).</td></tr>
                                    <tr><td><strong>Bul-Kathos:</strong></td></tr>
                                    <tr><td>Pasa de +20 p. de daño de fuego a +200 p. de daño de fuego (conjunto completo).</td></tr>
                                    <tr><td>Pasa de +25 p. de defensa a +200 p. de defensa (conjunto completo).</td></tr>
                                    <tr><td>+10 % de vida robada por golpe (conjunto completo).</td></tr>
                                    <tr><td>+20 % de azote mortal (conjunto completo).</td></tr>
                                    <tr><td><strong>Civerb:</strong></td></tr>
                                    <tr><td>Pasa de +15 % de resistencia al fuego a +25 % de resistencia al fuego (2 objetos).</td></tr>
                                    <tr><td>25 % de bonus al índice de ataque (conjunto completo).</td></tr>
                                    <tr><td>+50 p. de defensa (conjunto completo).</td></tr>
                                    <tr><td><strong>Iratha:</strong></td></tr>
                                    <tr><td>+24 % de ataque perforador (3 objetos).</td></tr>
                                    <tr><td><strong>Sazabi:</strong></td></tr>
                                    <tr><td>Duración del veneno reducida un 75 % (2 objetos).</td></tr>
                                    <tr><td>+1 p. a todas las habilidades (conjunto completo).</td></tr>
                                    <tr><td>Daño reducido un 16 % (conjunto completo).</td></tr>
                                    <tr><td><strong>Equipo ártico:</strong></td></tr>
                                    <tr><td>Pasa de 6-14 p. de daño de frío a +2-198 p. de daño máximo de frío (+2 p. por nivel del personaje) (conjunto completo).</td></tr>
                                    <tr><td><strong>Rey de las Vacas:</strong></td></tr>
                                    <tr><td>+100 p. de defensa (conjunto completo).</td></tr>
                                    <tr><td>+100 p. de vida (conjunto completo).</td></tr>
                                    <tr><td>+1 p. a todas las habilidades (conjunto completo).</td></tr>
                                    <tr><td><strong>Milabrega:</strong></td></tr>
                                    <tr><td>Se ha añadido «No te pueden congelar» (3 objetos).</td></tr>
                                    <tr><td>+2-198 p. de daño de rayos (+2 p. por nivel del personaje) (2 objetos).</td></tr>
                                    <tr><td><strong>Vidala:</strong></td></tr>
                                    <tr><td>7 % de maná robado por golpe (2 objetos).</td></tr>
                                    <tr><td>Pasa de 15-20 p. de daño de frío a +1-148 p. de daño máximo de frío (+1,5 p. por nivel del personaje) (conjunto completo).</td></tr>
                                </tbody>
                            </table>
                        </div>` 
            },
            { 
                name: '*Mercenarios*', 
                text: `<div class="pricing-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th style="text-align: center; font-size: 1.3em;">Cambios en los Mercenarios:</th>
                                    </tr>
                                </thead>
                                                                <thead>
                                    <tr>
                                        <th style="text-align: center; font-size: 1.3em;">Ahora pueden utilizar FULL SET!!</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td><strong>Acto I:</strong></td></tr>
                                    <tr><td><strong>Arquera Arpía:</strong></td></tr>
                                    <tr><td>Se actualizaron los Stats/Skills.</td></tr>
                                    <tr><td>Flecha de frío: Se ha añadido Flecha glacial.</td></tr>
                                    <tr><td>Flecha de fuego: Se ha añadido Flecha explosiva.</td></tr>
                                    <tr><td><strong>Acto II:</strong></td></tr>
                                    <tr><td><strong>Mercenario del Desierto:</strong></td></tr>
                                    <tr><td>Se actualizan los Stats/Skills.</td></tr>
                                    <tr><td>Espinas (combate - Pesadilla): Se ha aumentado la progresión por nivel del aura Espinas.</td></tr>
                                    <tr><td><strong>Acto III:</strong></td></tr>
                                    <tr><td><strong>Lobo de Hierro:</strong></td></tr>
                                    <tr><td>Se ha aumentado la vida 25%, defensa 40%, resistencias 20%.</td></tr>
                                    <tr><td>Frío: Ahora lanzará Punta glacial con más frecuencia y tiene más dmg. Se ha intercambiado Armadura helada por Armadura glacial.</td></tr>
                                    <tr><td>Fuego: Se ha eliminado Infierno. Se ha añadido Saeta a fuego y se aumenta la chance de uso.</td></tr>
                                    <tr><td>Se ha añadido Encantar: ahora el Lobo de Hierro lanzará Encantar sobre sí mismo, el jugador y los aliados cercanos.</td></tr>
                                    <tr><td>Rayos: Se ha añadido Campo estático. Se ha aumentado la probabilidad de usar Rayo.</td></tr>
                                    <tr><td><strong>Acto V: </strong><img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td><strong>Guerrero Bárbaro:</strong></td></tr>
                                    <tr><td>Utilizan la habilidad Frenesí MEJORADA. Sus stats mejoran segun dificultad siendo el mejor en Infierno y cualquiera que contrates da igual. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                    <tr><td>Se actualizaron los Stats/Skills (ahora tiene más vida y defensa que los demás mercenarios).</td></tr>
                                    
                                    
                                </tbody>
                            </table>
                        </div>`
            },
            { 
                name: 'Runewords', 
                text: `<div class="pricing-table">
                            <table>
                                <thead>
                                   <tr>
                                        <td style="text-align: center; font-size: 1.0em;">Runewords Habilitadas:</td>
                                    </tr>
                                                                            <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Tierra/Hogar/Temperamento</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Bruma</td>
                                        </tr>
                                                                                <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Temperamento</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Cura</td>
                                        </tr>
                                                                                <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Apremio</td>
                                        </tr>
                                                                            </tr>
                                        <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Peste</td>
                                        </tr>
                                                                            </tr>
                                        <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Patrón</td>
                                        </tr>
                                                                                <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Voluntad de Hierro</td>
                                        </tr>
                                                                                <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Obsesión</td>
                                        </tr>
                                                                                <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Sabiduría</td>
                                        </tr>
                                                                                <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Llama Trémula</td>
                                        </tr>
                                                                                <tr>
                                                                                                                        <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Metamorfosis también da exp auemtenda</td>
                                        </tr>
                                                                                </tr>
                                                                                <tr>
                                                                                                                        <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Perspicacia: Ahora también se puede usar con arcos y ballestas.</td>
                                        </tr>
                                                                                                                        <tr>
                                                                                                                        <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Infinito: Ahora se puede usar en lanzas y lanzas de amazona.</td>
                                        </tr>
                                                                                                                        <tr>
                                                                                                                        <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Obediencia: Ahora se puede usar en lanzas y lanzas de amazona.</td>
                                        </tr>
                                                                                                                        <tr>
                                                                                                                        <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Orgullo: Ahora se puede usar en lanzas y lanzas de amazona.</td>
                                        </tr>
                                                                                <tr>
                                            <td style="text-align: center; font-size: 1.0em;">Mosaico (NO conserva cargas).</td>
                                        </tr>
                                            <td style="text-align: center; font-size: 1.0em;">Búscalas en d2mantix.com</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>`
            },
        ],

        
"Cofre De Items": [
    {
        name: 'Como usar',
        text: `
<!-- Contenedor principal -->
<div style="display: flex; flex-direction: column; align-items: center; margin: 20px;">

    <!-- Tabla superior con margen ajustado -->
    <div class="pricing-table">
        <table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
            <thead>
                <tr>
                    <th colspan="2" style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;">¿Cómo utilizar el Cofre?</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="padding: 10px;">Debes transmutar cualquier item único junto al Cofre y obtendrás Puntos Únicos</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">Cada item único que guardes te otorgará Puntos Únicos</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">Recuerda que una vez que hayas guardado dicho item no lo podrás recuperar.</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">Para conocer qué items puedes canjear y cuántos puntos necesitas</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">Visita a Akara y lee la descripción del extractor del item que deseas conseguir.</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">Si ya tienes los puntos necesarios simplemente transmuta el Cofre + El Extractor del Item deseado.</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Contenedor para el GIF -->
    <div style="margin: 5px 0; text-align: center;">
<video 
    src="${CofreVid}" 
    alt="vid1"
    controls 
    loop 
    muted
    autoplay
    class="responsive-video"
>
    Tu navegador no soporta la etiqueta de video.
</video>
    </div>

    <!-- Tabla inferior -->
    <div class="pricing-table" style="width: 100%; max-width: 800px;">
        <table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
            <thead>
                <tr>
                    <th colspan="2" style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;">Detalles Técnicos:</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="padding: 10px;">Todos los Items Únicos otorgan 25. Excepto estos Items que NO pueden ser almacenados para seguir manteniendo la esencia del Trade.</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">Items que NO otorgan puntos: Deaths's Web, Griffon's Eye, Crown of Ages, Fathom, Shadowdancer, Eschuta's Temper, Waterwalk, Harlequin Crest, Arachnid Mesh, Verdugo's Hearty Cord, Skin of the Vipermagi, Azurewrath, Ormus' Robes, Sandstorm Trek, Giantskull, Titan's Revenge, Andariel Visage, War Bonnet, Magefist, Bloodfist, Ningun Anillo/Amuleto, Butcher's Pupil, Shaftstop, Duriel's Shell, Skullder's Ire, Viper, Corona de los Ladrones, Stormshield, Padrino, Punta Mágica, Kira, Draculs, Ondal, Iris, Warshrike, Steelrend, Bonehew, Heaven's Light, Nosferatu, Wolfhowl, Boneflame, Gimmershred, The Reaper's Toll, Stone Crusher, Jadetalon, Cerebus, Tomb Reaver, Fleshripper, Jalal, Arreat, Cordel de Orejas, Plague Bearer, Cetro Redentor, Zakarum, Razortyle, Vampiregaze, Lacerator, Firelizard Talon, Homunculus, DemonLimb, Sould Drain, Lava Gout, Ghoulhide.</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">Deseas combinar tu bolsa con la de otro jugador? Solo es posible mediante donaciones. Conoce el precio en la sección "Donaciones".</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2" style="padding: 10px; text-align: center; font-size: 1.0em; background-color: #444;">Recuerda solo debes extraer un Item por partida. No hay devoluciones.</td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

            
        `
    },
],


"Bolsa de Runas": [
    {
        name: 'Como usar',
        text: `
<!-- Contenedor principal -->
<div style="display: flex; flex-direction: column; align-items: center; margin: 20px;">

    <!-- Tabla superior con margen ajustado -->
    <div class="pricing-table" style="width: 100%; max-width: 650px;">
<table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
    <thead>
        <tr>
            <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;">¿Cómo utilizar la Bolsa de Runas?</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Debes transmutar cualquier Runa junto a la Bolsa de Runas y se acumularán</td>
        </tr>
        <tr>
            <td>Las runas se almacenan transmutando 1 en 1 y se sacan también de una al a vez</td>
        </tr>
        <tr>
            <td>Se recomienda utilizar varias bolsas si tu objetivo es vender por ejemplo</td>
        </tr>
        <tr>
            <td>60 Rals, de esa forma es más fácil el tradeo.</td>
        </tr>
        <tr>
            <td>Cuando quieras extraer 1 runa debes comprar su extractor en Akara</td>
        </tr>
        <tr>
            <td>Y luego transmutarlo junto a la Bolsa de Runas</td>
        </tr>
    </tbody>
</table>


    <!-- Contenedor para los videos -->
    <div style="margin: 20px 0; text-align: center; width: 100%; max-width: 800px; display: flex; flex-direction: column; align-items: center; gap: 20px;">
        <video 
            src="${GuardarVid}" 
            alt="vid1"
            controls 
            loop 
            muted
            autoplay
            class="responsive-video"
            style="width: 100%; max-width: 800px; border-radius: 15px;" 
        >
            Tu navegador no soporta la etiqueta de video.
        </video>
       <h1>Nuevo Downgrade de Runas. A partir de JAH: </h1>
        <video 
            src="${DowngVid}" 
            alt="vid2"
            controls 
            loop 
            muted
            autoplay
            class="responsive-video"
            style="width: 100%; max-width: 800px; border-radius: 15px;" 
        >
            Tu navegador no soporta la etiqueta de video.
        </video>
    </div>

<!-- Tabla inferior -->
<div class="pricing-table" style="width: 100%; max-width: 800px;">
    <table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
        <thead>
            <tr>
                <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;">NUEVAS FORMULAS</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Ahora puedes obtener 40 runas de cada tipo, utilizando las siguientes combinaciones:</td>
            </tr>
            <tr><td>Obten 40 Nef Transmutando: Lo + Extractor Nef + Bolsa</td></tr>
            <tr><td>Obten 40 Ith Transmutando: Lo + Extractor Ith + Bolsa</td></tr>
            <tr><td>Obten 40 Tal Transmutando: Lo + Extractor Tal + Bolsa</td></tr>
            <tr><td>Obten 40 Ral Transmutando: Lo + Extractor Ral + Bolsa</td></tr>
            <tr><td>Obten 40 Ort Transmutando: Lo + Extractor Ort + Bolsa</td></tr>
            <tr><td>Obten 40 Thul Transmutando: Lo + Extractor Thul + Bolsa</td></tr>
            <tr><td>Obten 40 Amn Transmutando: Lo + Extractor Amn + Bolsa</td></tr>
            <tr><td>Obten 40 Sol Transmutando: Lo + Extractor Sol + Bolsa</td></tr>
            <tr><td>Obten 40 Hel Transmutando: Lo + Extractor Hel + Bolsa</td></tr>
        <tfoot>
        <tr>
            <td colspan="2" style="text-align: center; font-size: 1.8em; padding: 10px; background-color: #444;"></td>
        </tr>
    </tfoot>
        </tbody>
    </table>
</div>

    </div>
</div>

            
        `
    },
],

        "*Extra*": [
            { name: 'Bolsa de Gemas', text: `<div class="pricing-table"><table style="width: 100%; max-width: 650px; border-collapse: collapse; overflow-x: auto;">
    <thead>
        <tr>
            <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;" colspan="2">USO DE LA BOLSA DE GEMAS:</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Te permite almacenar tus Gemas Perfectas.</td>
        </tr>
        <tr>
            <td>Se recomienda almacenar las Amatistas en una bolsa diferente para tradearlas más fácil.</td>
        </tr>
        <tr>
            <td>Introduce 3 gemas "Sin Defectos" para obtener 1 gema perfecta automáticamente.</td>
        </tr>
        <tr>
            <td>Se pueden agregar hasta 6 gemas "Sin Defectos" a la vez para obtener 2 gemas perfectas.</td>
        </tr>
                <tr>
            <td>El Guante Extractor puede ser transmutado para elegir que gema extraer. Máximo una a la vez.</td>
        </tr>
    </tbody>
    <thead>
        <tr>
            <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;" colspan="2">NUEVAS FORMULAS:</th>
        </tr>
    </thead>
        <tr>
            <td>Consigue 40 Amatistas Transmutando Lo + Bolsa + Guante Extractor de Amatistas</td>
        </tr>
              <tr>
            <td>Consigue 40 Craneos Transmutando Lo + Bolsa + Guante Extractor de Craneos</td>
        </tr>
        <tr>
            <td>Consigue 40 Topaz Transmutando Ohm + Bolsa + Guante Extractor de Topacios</td>
        </tr>
        <tr>
            <td>Consigue 40 Zafiros Transmutando Ohm + Bolsa + Guante Extractor de Zafiros</td>
        </tr>
        <tr>
            <td>Consigue 40 Esmeraldas Transmutando Ohm + Bolsa + Guante Extractor de Esmeraldas</td>
        </tr>
        <tr>
            <td>Consigue 40 Rubies Transmutando Ohm + Bolsa + Guante Extractor de Rubies</td>
        </tr>
        <tr>
            <td>Consigue 40 Diamante Transmutando Ohm + Bolsa + Guante Extractor de Diamantes</td>
        </tr>  
</table>

` },
            { name: 'Bolsa de Joyas', text: `<div class="pricing-table"><table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
    <thead>
        <tr>
            <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;" colspan="2">Bolsa de Joyas:</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>NO debes guardar joyas de valor ya que no las almacena solo suma al contador, es decir al momento de extraer te va a salir una joya random Ilvl1 por lo que no existe posibilidad de que salga algo de valor (los Ilvl de las joyas no afectan al crafteo). La administración no se responsabiliza por tu mala utilización.</td>
        </tr>
    </tbody>
        <tr>
            <td> No es necesario ningún guante extractor, solo se transmuta y extrae de a 1 Joya a la vez.</td>
        </tr>
        <tfoot>
        <tr>
            <td colspan="2" style="text-align: center; font-size: 1.8em; padding: 10px; background-color: #444;">Ahora puedes obtener 40 Joyas transmutando la Bolsa + Runa VEX.</td>
        </tr>
    </tfoot>
</table>
</div>` },
            { name: 'Recolector de Llaves', text: `<div class="pricing-table"><table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
    <thead>
        <tr>
            <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;" colspan="2">Recolector de Llaves:</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Llaves se almacenan transmutando 1 a la vez y se extraen trasmutando solo el recolector.</td>
        </tr>
         <tr>
            <td>Se almacenan de hasta 5 a la vez.</td>
        </tr>
                 <tr>
            <td>Se extraen de a 1 a la vez.</td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <td colspan="2" style="text-align: center; font-size: 1.1em; padding: 10px; background-color: #444;">(NO usan guante extractor).</td>
        </tr>
    </tfoot>
</table>
</div>` },
            { name: 'Craft / Roll', text: `<div class="pricing-table"><table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
    <thead>
        <tr>
            <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;" colspan="2">Nueva forma de Roll con la Bolsa de Gemas:</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Se puede rollear GC, MC, SC Coronas/Tiaras/Diademas/Disfraz del Cazador(Druida)/Casco Barbaro/Mitones de Zarza, Espada del Coloso, Suwayah (con Cráneos) directamente transmutando la bolsa de gemas + el item.</td>
        </tr>
        <td>Tip: Puedes usar cualquier Ilvl ya que siempre saldrá 94. Los GC Siempre Salen ilvl 99.</td>
        </tr>
        <td>Las Amatistas y Cráneos están protegidas y no serán consumidas al cocinar de esta manera. Y solo se gastarán si introduces Tiaras.</td>
        </tr>
    <thead>
        <tr>
            <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;" colspan="2">Nueva forma de Crafteo:</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>No es necesario extraer las Amatistas. Introduce Bolsa de Gemas que contenga Amatistas + 1 Joya +1 Ral +1 Amuleto azul. </td>

        </tr>
                <tr>
            <td>Puedes reutilizar el Amuleto Craft sin necesidad de comprar nuevos amuletos azules!</td>

        </tr>
                </tr>
                <tr>
            <td>Funciona con TODOS los tipos de crafteo. (Amuletos,Guantes,Botas,Anillos).</td></td>

        </tr>
                        <tr>
            <td>El ilvl siempre es en base al PJ, como siempre lo fue.</td></td>

        </tr>

    </tbody>
</table>


</div>` },
{ name: '*Nuevas Formulas*', text: `<div class="pricing-table"><table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
    <thead>
        <tr>
            <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;" colspan="1">Nuevas Formulas:</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Transmutando Tiara/Aro/Corona/Diadema/Casco Druida Amarillo + Jah = 2 Huecos</td>
        </tr>
    </tbody>
        <tr>
            <td>Transmutando Tiara/Diadema Azul + Jah = 3 Huecos</td>
        </tr>
        <tr>
            <td>NO funciona con "El Aro ni La Corona" azul. Puedes donar para upgradearlo y te deje tener 3 huecos.</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
            <td colspan="1" style="text-align: center; font-size: 1.8em; padding: 10px; background-color: #444;"></td>
        </tr>
    </tfoot>
</table>
</div>` },
{ name: '*Formulas Hechizos con HP*', text: `<div class="pricing-table"><table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
    <!-- Sección Hechicera -->
    <thead>
      <tr>
        <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;">Utiliza Control+F para buscar tu Clase:</th>
      </tr>
    </thead>
    <tbody>
    <tr><td><strong>HECHICERA:</strong></td></tr>
      <tr><td><strong>Rama Fuego:</strong></td></tr>
      <tr><td>Gc aleatorio + Runa Jah + Rubi fragmentado --> Gc +1 Fire Skills con 31-35hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Sur + rubi fragmentado --> Gc +1 Fire Skills con 36-40hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Ber + Runa Lo + rubi fragmentado --> Gc +1 Fire Skills con 41-45hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + Rubi fragmentado --> Gc +1 Fire con FHR</td></tr>
      
      <tr><td><strong>Rama Hielo:</strong></td></tr>
      <tr><td>Gc aleatorio + Runa Jah + Rubi sin defectos --> Gc +1 Cold Skills con 31-35hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Sur + Rubi sin defectos --> Gc +1 Cold Skills con 36-40hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Ber + Runa Lo + rubi sin defectos --> Gc +1 Cold Skills con 41-45hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + Rubi sin defectos --> Gc +1 Frio con FHR</td></tr>
      
      <tr><td><strong>Rama Rayos:</strong></td></tr>
      <tr><td>Gc aleatorio + Runa Jah + Rubi perfecto --> Gc +1 Light Skills con 31-35hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Sur + Rubi perfecto --> Gc +1 Light Skills con 36-40hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Ber + Runa Lo + rubi perfecto --> Gc +1 Light Skills con 41-45hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + Rubi Perfecto --> Gc +1 Rayos con FHR</td></tr>

      <tr><td><strong>PALADIN:</strong></td></tr>
      <tr><td><strong>Rama Aura Def:</strong></td></tr>
      <tr><td>Gc aleatorio + Runa Gul + diamante fragmentado --> Gc +1 Aura Defensiva con 31-35hp</td></tr>
      <tr><td>Gc aleatorio + Runa Vex + diamante fragmentado --> Gc +1 Aura Defensiva con 36-40hp</td></tr>
      <tr><td>Gc aleatorio + Runa Ohm + diamante fragmentado --> Gc +1 Aura Defensiva con 41-45hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + Diamante fragmentado --> Gc +1 Aura Defensiva con FHR</td></tr>
      
      <tr><td><strong>Rama Aura Off:</strong></td></tr>
      <tr><td>Gc aleatorio + Runa Ohm + diamante sin defectos --> Gc +1 Aura Ofensiva con 31-35hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + diamante sin defectos --> Gc +1 Aura Ofensiva con 36-40hp</td></tr>
      <tr><td>Gc aleatorio + Runa Sur + diamante sin defectos --> Gc +1 Aura Ofensiva con 41-45hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + Diamante sin defectos --> Gc +1 Aura Ofensiva con FHR</td></tr>
      
      <tr><td><strong>Rama Combate:</strong></td></tr>
      <tr><td>Gc aleatorio + Runa Ber + diamante perfecto --> Gc +1 Habilidades de Combate con 31-35hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Lo + diamante perfecto --> Gc +1 Habilidades de Combate con 36-40hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Sur + Runa Lo + diamante perfecto --> Gc +1 Habilidades de Combate con 41-45hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + Runa Vex + Estandarte + Diamante perfecto --> Gc +1 Combate Pala con FHR</td></tr>
      
      <tr><td><strong>AMAZONA:</strong></td></tr>
      <tr><td><strong>Rama Arco y Ballesta:</strong></td></tr>
      <tr><td>Gc aleatorio + Runa Gul + amatista fragmentada --> Gc +1 Bow & Crossbow Skills con 31-35hp</td></tr>
      <tr><td>Gc aleatorio + Runa Vex + amatista fragmentada --> Gc +1 Bow & Crossbow Skills con 36-40hp</td></tr>
      <tr><td>Gc aleatorio + Runa Ohm + amatista fragmentada --> Gc +1 Bow & Crossbow Skills con 41-45hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + amatista fragmentada --> Gc +1 Arco con FHR</td></tr>
      
      <tr><td><strong>Rama Pasivas y Mágicas:</strong></td></tr>
      <tr><td>Gc aleatorio + Runa Ohm + amatista sin defectos --> Gc +1 Passive & Magic Skills con 31-35hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + amatista sin defectos --> Gc +1 Passive & Magic Skills con 36-40hp</td></tr>
      <tr><td>Gc aleatorio + Runa Sur + amatista sin defectos --> Gc +1 Passive & Magic Skills con 41-45hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + amatista sin defectos --> Gc +1 Pasivas con FHR</td></tr>
      
      <tr><td><strong>Rama Jabalina y Lanza:</strong></td></tr>
      <tr><td>Gc aleatorio + Runa Ber + amatista perfecta --> Gc +1 Javelin & Spear Skills con 31-35hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Lo + amatista perfecta --> Gc +1 Javelin & Spear Skills con 36-40hp</td></tr>
      <tr><td>Gc aleatorio + Hierba + Runa Sur + Runa Lo + amatista perfecta --> Gc +1 Javelin & Spear Skills con 41-45hp</td></tr>
      <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + Amatista Perfecta --> Gc +1 Java con FHR</td></tr>
    
        <!-- Barbaro -->
    <tr><td><strong>BARBARO:</strong></td></tr>
    <tr><td><strong>Rama Gritos de Guerra:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Vex + esmeralda perfecta --> Gc +1 Warcries con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Runa Ohm + esmeralda perfecta --> Gc +1 Warcries con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + esmeralda perfecta --> Gc +1 Warcries con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + esmeralda perfecta --> Gc +1 Grito con FHR</td></tr>

    <tr><td><strong>Rama Dominios:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Vex + esmeralda fragmentada --> Gc +1 Dominios con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Runa Ohm + esmeralda fragmentada --> Gc +1 Dominios con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + esmeralda fragmentada --> Gc +1 Dominios con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + esmeralda fragmentada --> Gc +1 Dominios con FHR</td></tr>

    <tr><td><strong>Rama Combate:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Ohm + esmeralda sin defectos --> Gc +1 Combat Skills con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + esmeralda sin defectos --> Gc +1 Combat Skills con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Runa Sur + esmeralda sin defectos --> Gc +1 Combat Skills con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + esmeralda sin defectos --> Gc +1 Combate con FHR</td></tr>

    <!-- Druida -->
    <tr><td><strong>DRUIDA:</strong></td></tr>
    <tr><td><strong>Rama Elemental:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Ber + zafiro perfecto --> Gc +1 Elemental Skills con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Hierba + Runa Lo + zafiro perfecto --> Gc +1 Elemental Skills con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Hierba + Runa Sur + Runa Lo + zafiro perfecto --> Gc +1 Elemental Skills con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + zafiro perfecto --> Gc +1 Elemental con FHR</td></tr>

    <tr><td><strong>Rama Invocacion:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Gul + zafiro fragmentado --> Gc +1 Summoning Skills con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Runa Vex + zafiro fragmentado --> Gc +1 Summoning Skills con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Runa Ohm + zafiro fragmentado --> Gc +1 Summoning Skills con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + zafiro fragmentado --> Gc +1 Invocacion con FHR</td></tr>

    <tr><td><strong>Rama Cambio de Forma:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Vex + zafiro sin defectos --> Gc +1 Shape Shifting Skills con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Runa Ohm + zafiro sin defectos --> Gc +1 Shape Shifting Skills con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + zafiro sin defectos --> Gc +1 Shape Shifting Skills con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + zafiro sin defectos --> Gc +1 Cambio y Forma con FHR</td></tr>
    
        <!-- Asesina -->
    <tr><td><strong>ASESINA:</strong></td></tr>
    <tr><td><strong>Rama Trampas:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Ber + topacio perfecto --> Gc +1 Traps Skills con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Hierba + Runa Lo + topacio perfecto --> Gc +1 Traps Skills con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Hierba + Runa Sur + Runa Lo + topacio perfecto --> Gc +1 Traps Skills con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Vex + Estandarte + topacio perfecto --> Gc +1 Trampas con FHR</td></tr>

    <tr><td><strong>Rama Artes Marciales:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Ohm + topacio fragmentado --> Gc +1 Martial Arts Skills con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + topacio fragmentado --> Gc +1 Martial Arts Skills con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Runa Sur + topacio fragmentado --> Gc +1 Martial Arts Skills con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + topacio fragmentado --> Gc +1 Artes M. con FHR</td></tr>

    <tr><td><strong>Rama Disciplinas de las Sombras:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Ohm + topacio sin defectos --> Gc +1 Shadow Disciplines con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Ohm + topacio sin defectos --> Gc +1 Shadow Disciplines con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Runa Sur + Lo + topacio sin defectos --> Gc +1 Shadow Disciplines con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Vex + Runa Gul + Estandarte + topacio sin defectos --> Gc +1 Shadow con FHR</td></tr>

    <!-- Nigromante -->
    <tr><td><strong>NIGROMANTE:</strong></td></tr>
    <tr><td><strong>Rama Invocacion:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Gul + craneo fragmentado --> Gc +1 Summoning Skills con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Runa Vex + craneo fragmentado --> Gc +1 Summoning Skills con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Runa Ohm + craneo fragmentado --> Gc +1 Summoning Skills con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + craneo fragmentado --> Gc +1 Invocacion con FHR</td></tr>

    <tr><td><strong>Rama Maldiciones:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Gul + craneo sin defectos --> Gc +1 Curses con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Runa Vex + craneo sin defectos --> Gc +1 Curses con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Runa Ohm + craneo sin defectos --> Gc +1 Curses con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + craneo sin defectos --> Gc +1 Maldiciones con FHR</td></tr>

    <tr><td><strong>Rama Veneno y Hueso:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Jah + craneo perfecto --> Gc +1 Poison & Bone Skills con 31-35hp</td></tr>
    <tr><td>Gc aleatorio + Hierba + Runa Sur + craneo perfecto --> Gc +1 Poison & Bone Skills con 36-40hp</td></tr>
    <tr><td>Gc aleatorio + Hierba + Runa Ber + Runa Lo + craneo perfecto --> Gc +1 Poison & Bone Skills con 41-45hp</td></tr>
    <tr><td>Gc aleatorio + Runa Lo + Runa Gul + Estandarte + craneo perfecto --> Gc +1 P&B con FHR</td></tr>
    

    <tr><td><strong>HECHIZOS DE DAÑO / AR / VIDA:</strong></td></tr>

    <tr><td><strong>Hechizos Grandes:</strong></td></tr>
    <tr><td>Gc aleatorio + Runa Ber + Estandarte de héroes --> Gc +7-10dmg / +49-76ar / +31-35hp</td></tr>
    <tr><td>Gc aleatorio + Hierba + Runa Lo + Estandarte de héroes --> Gc +7-10dmg / +49-76ar / +36-40hp</td></tr>
    <tr><td>Gc aleatorio + Hierba + Runa Sur + Runa Lo + Estandarte de héroes --> Gc +7-10dmg / +49-76ar / +41-45hp</td></tr>
    <tr><td>Mc Dmg/Ar/Hp: HP 31-35 Hp / DMG 4-6 / AR aleatorio = Gc + Esencia Roja + Sur + Lo</td></tr>

    <tr><td><strong>Hechizos Pequeños:</strong></td></tr>
    <tr><td>Sc aleatorio + Runa Sur + Estandarte de Héroes --> Sc +1-3dmg / +10-20ar / +16-20hp</td></tr>
     <tr><td>Sc aleatorio + Runa Lo + Runa Vex + Diamente Perf + Estandarte  --> Sc +1-3Frw / +10-20ar / +16-20hp</td></tr>
    <tr><td>Sc aleatorio + Runa Lo + Runa Vex + Estandarte --> Sc +16-20hp / +14-17mp</td></tr>

    <!-- Hechizos de Vida + Resistencias -->
    <tr><td><strong>HECHIZOS DE VIDA + RESISTENCIAS:</strong></td></tr>
    <tr><td>Sc 16-20 Hp - 10-11 Resistencia Frío = Sc + Esencia Azul + Ohm + Vex</td></tr>
    <tr><td>Sc 16-20 Hp - 10-11 Resistencia Fuego = Sc + Esencia Roja + Ohm + Vex</td></tr>
    <tr><td>Sc 16-20 Hp - 10-11 Resistencia Rayo = Sc + Esencia Amarilla + Ohm + Vex</td></tr>
    <tr><td>Sc 16-20 Hp - 10-11 Resistencia Veneno = Sc + Esencia Verde + Ohm + Vex</td></tr>
    <tr><td>Gc HP 41-45 / Mp 53-59 = Gc + Esencia Azul + Sur + Lo</td></tr>

    <!-- Hechizos Mc/Gc HP MP -->
    <tr><td><strong>Hechizos Mc/Gc HP MP:</strong></td></tr>
    <tr><td>Gc HP 41-45 / Mp 53-59 = Esencia Azul + Sur + Lo</td></tr>
    <tr><td>Mc Hp/Mp - +31Hp /+31 Mp = Mc + Esencia Azul + Sur + Ohm</td></tr>

    <tr><td><strong>Joyas:</strong></td></tr>

    <tr><td>Jwl Ias 31 - 40 ED Jah + Flawless Amethyst+Estandarte+ Pergamino Ciudad</td></tr>
    <tr><td>Jwl Fhr Cold 16 - 30 Sur + Flawless Saphiro +Estandarte+ Pergamino Ciudad</td></tr>
    <tr><td>Jwl Fhr Fire 16 - 30 Sur + Flawless Ruby +Estandarte+ Pergamino Ciudad</td></tr>
    <tr><td>Jwl Fhr Rayo 16 - 30 Sur + Flawless Topaz +Estandarte+ Pergamino Ciudad</td></tr>
    <tr><td>Jwl Fhr Veneno 16 - 30 Sur + Flawless Emerals +Estandarte+ Pergamino Ciudad</td></tr>
    <tr><td>Jwl Fhr Mana 16 - 20 Sur + Flawless Skull + Estandarte+ Pergamino Ciudad</td></tr>
    <tr><td>Jwl IAS Fire 16 - 30 Sur + Perfect Fire +Estandarte+ Pergamino Identidad</td></tr>
    <tr><td>Jwl IAS Rayo 16 - 30 Sur + Perfect Topaz  +Estandarte+ Pergamino Identidad</td></tr>
    <tr><td>Jwl IAS Veneno 16 - 30 Sur + Perfect Emeral  +Estandarte+ Pergamino Identidad</td></tr>
    <tr><td>Jwl IAS Cold 16 - 30 Sur + Perfect Saphiro  +Estandarte+ Pergamino Identidad</td></tr>
    <tr><td>Jwl IAS All Resis 11 - 15 Sur + Perfect Diamond  +Estandarte+ Pergamino Identidad</td></tr>



      </tbody>


        <tfoot>
        <tr>
            <td colspan="1" style="text-align: center; font-size: 1.8em; padding: 10px; background-color: #444;"></td>
        </tr>
    </tfoot>
</table>
</div>` },
        ],
        "*Personajes*": [
    { 
        name: '*Hechicera*', 
        text: `<div class="pricing-table">
                    <table>
                        <thead>
                            <tr>
                                <th style="text-align: center; font-size: 1.3em;">FRÍO/FUEGO/RAYOS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>Telekinesis ahora tiene Stun, Mas Rango y permite levantar TODOS los items. Habilitando combos en PvP <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                            <tr><td>Rayo: Se aumenta la velocidad del misil y su alcance. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                            <tr><td>Nova: Nueva sinergia con Campo Estático entrega 5% Daño por nivel.</td></tr>
                            <tr><td>Tormenta atronadora: Se ha añadido sinergia con Campo Estático: +7 %, Duración de 32s a 144s e incrementa por nivel.</td></tr>
                            <tr><td>Hidras: Tiempo de reutilización de Hydra nulo, máximo de Hidras 06.</td></tr>
                            <tr><td>Muro de fuego: Se ha incrementado notablemente su daño.</td></tr>
                            <tr><td>Llamarada: Se ha aumentado la progresión del daño alrededor de un 60 %. Sinergia con Calor de un 4 % a un 6 %. Se ha eliminado la sinergia con el Muro de fuego.</td></tr>
                            <tr><td>Inferno: Se ha aumentado el daño base y la progresión por nivel alrededor de un 75 % (también para compensar el cálculo incorrecto del daño de antes). Se ha aumentado la sinergia con Calor de un 13 % a un 16 %. Se costo de mana reducido de 7 a 4 p/s. Alcance aumentado un 75 %.</td></tr>
                            <tr><td>Nova de escarcha: Se ha aumentado la progresión del daño por nivel alrededor de un 25 %.</td></tr>
                            <tr><td>Armadura glacial: Se ha aumentado la progresión del daño por nivel alrededor de un 200 %. Se ha aumentado el valor base de la defensa de un 45 % a un 60 %. Se ha aumentado el bonus de defensa por nivel de un 5 % a un 7 %. Armadura helada de un 7 % a un 9 %.</td></tr>
                            <tr><td>Armadura gélida de un 7 % a un 9 %.</td></tr>
                            <tr><td>Armadura gélida: Se ha aumentado la progresión del daño por nivel alrededor de un 25 %.</td></tr>
                        </tbody>
                    </table>
                </div>` 
    },

    { 
        name: '*Asesina*', 
        text: `<div class="pricing-table">
                    <table>
                        <thead>
                            <tr>
                                <th style="text-align: center; font-size: 1.3em;">TRAMPAS</th>
                            </tr>
                        </thead>
                        <tbody>
                            


                            <tr>
                            <td>Estela Infernal: Sinergias aumentadas de 18 % a 25 %.
                            <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/>
                            </td></tr>
                            <td>Estela Infernal: Se aumenta el Rango y la Velocidad de disparo quedando igual a la de rayos<img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/>
                            </td></tr>

                            <tr><td>Ráfaga de fuego: Sinergias aumentadas de 11 % a 18 %. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                            <tr><td>Ráfaga de fuego: Se eliminan las sinergias con Trampas de Rayo. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                                        <tr><td>Bloqueo de Armas: Nuevo máximo es 55% y requiere 20 puntos+gear para ello. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                            <tr><td>Item único mejorado: Garfas del Lagarto. Ahora dan Skills Trampas de Fuego y te ayudan a llegar al break de ias. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                                                        <tr><td>Estela Infernal: Sinergia con Centinela Mortal Removido.</td></tr>
<tr><td>Centinela de rayos: Sinergia con Centinela Mortal Removido.</td></tr>
                                                        <tr><td>Centinela de rayos: Sinergias aumentadas de 12 % a 18 %.</td></tr>

                                                        <tr><td>Red de conmoción: Sinergia con Centinela Mortal Removida.</td></tr>
                            <tr><td>Red de conmoción: Sinergias aumentadas de 11 % a 17 %.</td></tr>
                            <tr><td><strong>ARTES MARCIALES</strong></td></tr>
                            <tr><td>Garra de Dragón: Daño aumentado por nivel de 5 % a 20 %.</td></tr>
                            <tr><td>Vuelo de Dragón: Daño aumenta por nivel de 25 % a 40 %, no tiene CD.</td></tr>
                            <tr><td>Cola de Dragón: Daño aumenta por nivel de 10 % a 20 %.</td></tr>
                            <tr><td>Puños de fuego, Garras del trueno, Espadas de Hielo, Azote del tigre, Azote de la cobra, Azote del fénix: Sinergias aumentadas de 15 % a 25 % y el incremento por nivel de un 7 % a 10 %.</td></tr>
                            <tr><td>Centinela con espada: Retardo reducido de 2 a 1, velocidad de misiles aumentada un 20 %, daño de arma aumentado de 37 % a 75 %, Sinergias: Furia de Espadas y Escudo de Espadas +10 % daño.</td></tr>
                            <tr><td>Escudo de Espadas: Duración de 20s a 120s y aumenta por nivel, daño de arma aumentado de 25 % a 75 %, se agrega sinergia con Centinela de Espadas y Furia de Espadas.</td></tr>
                            <tr><td>Veneno: Se aumenta la duración de 4s a 12s.</td></tr>
                            <tr><td>Ahora ambas Trampas son beneficiadas con las Iris/Grifo.</td></tr>
                        </tbody>
                    </table>
                </div>` 
    },
    { 
        name: '*Barbaro*', 
        text: `<div class="pricing-table">
                    <table>
                        <thead>
                            <tr>
                                <th style="text-align: center; font-size: 1.3em;">General</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr><td>Frenesí: Ahora convierte 1 % de daño mágico por cada punto en Fresí y Oscilacion doble (total 40%). <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/></td></tr>
                            <tr><td>Concentrado: Sinergia de Aullido reemplazada por Órdenes de Batalla.</td></tr>
                            <tr><td>Lanzamiento doble: Daño aumentado por nivel de 8 % a 12 %.</td></tr>
                            <tr><td>Lanzamiento doble: Se añade bonus de daño de un 16 % que aumenta un 8 % por nivel.</td></tr>
                            <tr><td>Grito de Guerra: Se aumenta el daño alrededor de un 30 %.</td></tr>
                            <tr><td>Resguardo lúgubre: Se aumenta el radio y se añade sinergia con Buscar Poción 5 % por nivel.</td></tr>
                            <tr><td>Salto Ofensivo: Daño base aumentado de 100 % a 200 %, Índice de ataque base de un 50 % a 100 %, índice de ataque por nivel de 15 % a 20 % y se aumenta la sinergia de 10 % a 15 %.</td></tr>
                        </tbody>
                    </table>
                </div>` 
    },
    { 
        name: '*Paladín*', 
        text: `<div class="pricing-table">
                    <table>
                        <thead>
                            <tr>
                                <th style="text-align: center; font-size: 1.3em;">General</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr><td>Escudo Sagrado: Se reduce sinergia de defensa con Desafío de 15% a 8% <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/>
                        <tr><td>Escudo Sagrado: Se reduce la duración del Escudo de 15 minutos a 8 minutos. <img src="${NewRibbon}" alt="Icono New" style="width: 30px; height: 10px; position: relative; top: 10px;"/>
                        <tr><td>Puño de los Cielos: Hace daño a TODOS los enemigos, y casi no tiene Delay</td></tr>
                            <tr><td>Sacrificio: El daño a uno mismo se reduce de 8 % a 1 % según nivel.</td></tr>
                            <tr><td>Conversión: El % de conversión se aumentó de 50 % a 90 %.</td></tr>
                            <tr><td>Fuego Sagrado: Se ha aumentado el daño general de la habilidad en los niveles más altos. Se ha aumentado la sinergia con Resistencia al fuego de un 18 % al 21 %. Y la sinergia con Salvación de un 6 % a un 10 %.</td></tr>
                            <tr><td>Golpe Sagrado: Se ha aumentado el daño general de la habilidad.</td></tr>
                            <tr><td>Santuario: Se duplica el daño mágico original del aura.</td></tr>
                            <tr><td>Las auras de Resistencias (Fuego, Rayo y Frío) no entregan resistencia máxima a menos que las tengas activadas.</td></tr>
                        </tbody>
                    </table>
                </div>` 
    },
    { 
        name: 'Amazona', 
        text: `<div class="pricing-table">
                    <table>
                        <thead>
                            <tr>
                                <th style="text-align: center; font-size: 1.3em;">Java y Lanza</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>Poder Azotador: Sinergias aumentadas de 10 % a 14 %. Se ha eliminado la sinergia con la habilidad Furia de rayos.</td></tr>
                            <tr><td>Relámpago Azotador: Sinergias aumentadas de 8 % a 11 %. Se ha eliminado la sinergia con la habilidad Furia de rayos.</td></tr>
                            <tr><td>Azote Cargado: Sinergias aumentadas de 10 % a 14 %. Se ha eliminado la sinergia con la habilidad Furia de rayos.</td></tr>
                            <tr><td>Poder Azotador: Se ha eliminado la sinergia con la habilidad Furia de rayos. Sinergias aumentadas de 10 % a 14 %.</td></tr>
                            <tr><td>Empalamiento: Se ha eliminado la modificación del índice de ataque. Ahora siempre golpeará al objetivo. Ahora ralentiza al objetivo una cantidad porcentual durante un tiempo. (BETA)</td></tr>
                            <tr><td>Jabalina Venenosa: Se ha ajustado ligeramente la progresión del daño en niveles altos.</td></tr>
                            <tr><td>Jabalina Pestilente: Ahora el veneno tiene una duración fija de 3 s. Se ha aumentado la sinergia con Jabalina venenosa de +10 % a +14 %. Se ha reducido el retardo de lanzamiento de 4 s a 1 s.</td></tr>
                            <tr><td><strong>ARCO Y BALLESTA:</strong></td></tr>                           
                            <tr><td>Flecha Mágica: Se ha aumentado la cantidad de daño físico que se convierte en daño mágico en el nivel base y por nivel.</td></tr>
                            <tr><td>Flecha Glacial: Se ha reducido el coste de maná por nivel de +0,5 p. a +0,25 p.</td></tr>
                            <tr><td>Bombardeo: Se ha eliminado la reducción del daño de arma del 25 % de esta habilidad. Se agrega índice de ataque: +30 % de base y +9 % por nivel.</td></tr>
                            <tr><td>Flecha de Fuego: El costo de mana ya no aumenta.</td></tr>
                            <tr><td>Flecha Explosiva: Se ha aumentado la progresión del daño en los niveles más altos alrededor de un 50 %. Se ha aumentado la sinergia con Flecha de fuego de +12 % a +14 %. Se ha reducido el coste de maná por nivel de +0,5 p. a +0,25 p.</td></tr>
                        </tbody>
                    </table>
                </div>` 
    },
    { 
        name: 'Nigromante', 
        text: `<div class="pricing-table">
                    <table>
                        <thead>
                            <tr>
                                <th style="text-align: center; font-size: 1.3em;">Invocación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>Esqueletos Magos: Se han aumentado los PV por nivel de un 7 % a un 10 %. Se ha aumentado la progresión total del daño de veneno alrededor de un 750 %. La duración del veneno ya no progresa y ahora es de 4 s. Se ha aumentado la progresión del daño de frío alrededor de un 50 %. Se ha aumentado la progresión del daño de rayos alrededor de un 5 %.</td></tr>
                            <tr><td>Golem Sangriento: Ahora obtiene vida máxima por nivel y se ha incrementado la progresión de daño por nivel.</td></tr>
                            <tr><td>Golem de Fuego: Se ha aumentado el bonus por nivel de Fuego sagrado de 1 p. a 2 p.</td></tr>
                        </tbody>
                        <thead>
                            <tr>
                                <th style="text-align: center; font-size: 1.3em;">Veneno y Hueso</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>Armadura de Huesos: Daño absorbido por nivel aumentado de 10 a 15.</td></tr>
                            <tr><td>Lanza Ósea: Aumento de sinergias de 7 % a 8 %.</td></tr>
                            <tr><td>Espíritu de Hueso: Aumento de sinergias de 7 % a 8 %.</td></tr>
                            <tr><td>Maldición: Debilitar: Aumento de 30 % a 40 %.</td></tr>
                        </tbody>
                    </table>
                </div>` 
    },
    { 
        name: 'Druida', 
        text: `<div class="pricing-table">
                    <table>
                        <thead>
                            <tr>
                                <th style="text-align: center; font-size: 1.3em;">Elemental:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td><strong>Habilidades de Fuego:</strong></td></tr>
                            <tr><td>Armagedón: Aumento del radio de área y del daño físico. La sinergia con Volcán aumenta el daño físico.</td></tr>
                            <tr><td>Volcán: La sinergia con Roca ígnea se incrementa.</td></tr>
                            <tr><td>Roca Licada: Reducción del tiempo de lanzamiento. Mejora de la sinergia con Volcán.</td></tr>
                            <tr><td>Ráfaga Ártica: Eliminada la sinergia con Huracán. Aumento significativo del daño base y progresión.</td></tr>
                            <tr><td>Torbellino: Incremento del daño. Nueva sinergia con Explosión ártica.</td></tr>
                            <tr><td><strong>Invocación:</strong></td></tr>
                            <tr><td>Ahora se pueden utilizar todas las invocaciones juntas.</td></tr>
                            <tr><td>Mejoras en resistencias para Sabía de Roble, Corazón de Roble y Espíritu de púas.</td></tr>
                            <tr><td>Mejoras en daño y vida para Invocar Lobo Espíritu, Atroz y Oso Pardo.</td></tr>
                        </tbody>
                    </table>
                </div>` 
    },
        ],





        "*Hall of Fame*": [


            {
                name: 'Contador de Copas - PvM',
                text: `<div class="cups-table">
                           <table>
                               <tbody>
                                   <tr>
                                       <th style="text-align: left; font-size: 1.3em;" colspan="4" class="cups-header">TOTAL DE COPAS:</th>
                                   </tr>
                                   <tr>
                                       <th>Clan:</th>
                                       <th>Copas Propias:</th>
                                       <th>Copas Compartidas:</th>
                                       <th style="font-weight: bold;">Total:</th>
                                   </tr>
                                   <tr>
                                       <td>ThC-</td>
                                       <td>7</td>
                                       <td>3</td>
                                       <td style="font-weight: bold;">10</td>
                                   </tr>
                                   <tr>
                                       <td>Pawsa</td>
                                       <td>1</td>
                                       <td>1</td>
                                       <td>2</td>
                                   </tr>
                                                                                                         <tr>
                                       <td>Satu</td>
                                       <td>1</td>
                                       <td>0</td>
                                       <td>1</td>
                                   </tr>
                                                                      <tr>
                                       <td>Javipastore</td>
                                       <td>1</td>
                                       <td>0</td>
                                       <td>1</td>
                                   </tr>
                                   <tr>
                                       <td>TFT-</td>
                                       <td>0</td>
                                       <td>4</td>
                                       <td>4</td>
                                   </tr>

                                </tbody>
                           </table>
                       </div>
            

                       <div class="cups-table">
                           <table>
                               <tbody>
                                   <tr>
                                       <th style="text-align: center; font-size: 1.3em;">
                               TORNEOS:

                                   <tr>
                                       <th>Torneo:</th>
                                       <th>Personajes:</th>
                                       <th>Nombres:</th>
                                       <th>Clan:</th>
                                       <th>Temporada:</th>
                                    </tr>
                                                                                                      </tr>
                                                                       <tr>
                                       <td>Multiclass 3vs3</td>
                                       <td>Lema+Paqo+Maty</td>                               
                                       <td>Maty+Ivan</td>
                                       <td>ThC</td>
                                       <td>3</td>
                                   </tr>
                             
                                   </tr>
                                                                       <tr>
                                       <td>Multiclass 2vs2</td>
                                       <td>TFF-Zoro+ThC-LaraCroft</td>                               
                                       <td>Maty+Ivan</td>
                                       <td>Compartida</td>
                                       <td>3</td>
                                   </tr>
                                    <tr>
                                       <td>Multiclass Sets 2vs2</td>
                                       <td>TFF-Rouge+ThC-LaraCroft</td>                               
                                       <td>Maty+Ivan</td>
                                       <td>Compartida</td>
                                       <td>3</td>
                                   </tr>
                                                                                                                                            <tr>
                                       <td>Multiclass</td>
                                       <td>Satu</td>                               
                                       <td>Rastu</td>
                                       <td>No Tiene</td>
                                       <td>3</td>
                                   </tr>
                                   </tr>
                                                                                                                                            <tr>
                                       <td>Pala Ubber</td>
                                       <td>ThC-Nutella</td>                               
                                       <td>Lema</td>
                                       <td>ThC</td>
                                       <td>3</td>
                                   </tr>
                                                                                                         <tr>
                                       <td>LvL 9</td>
                                       <td>ThC-Japon</td>                               
                                       <td>Lema</td>
                                       <td>ThC</td>
                                       <td>3</td>
                                   </tr>
                                                                      <tr>
                                       <td>Sorc Mefera</td>
                                       <td>ThC-Malaga</td>                               
                                       <td>Lema</td>
                                       <td>ThC</td>
                                       <td>3</td>
                                   </tr>
                                   <tr>
                                       <td>2vs2 Random</td>
                                       <td>ThC-Akme+TFT-Seis</td>                               
                                       <td>Joan+Ivan</td>
                                       <td>ThC</td>
                                       <td>2</td>
                                   </tr>
                                   <tr> 
                                       <td>Only Sets</td>
                                       <td>Pawsa</td>                               
                                       <td>Nacho</td>
                                       <td>No Tiene</td>
                                       <td>2</td>
                                   </tr>
                                   <tr>
                                       <td>Multiclass 2vs2</td>
                                       <td>Pawsa+TFT-Ragnar</td>                               
                                       <td>Nacho+Agustin</td>
                                       <td>Compartida</td>
                                       <td>2</td>
                                   </tr>
                                   <tr>
                                       <td>Multiclass</td>
                                       <td>ThC-Chiterita</td>                               
                                       <td>Alan</td>
                                       <td>ThC</td>
                                       <td>2</td>
                                   </tr>
                                   <tr>
                                       <td>Pala Ubber</td>
                                       <td>ThC-Tyrael</td>                               
                                       <td>Joan</td>
                                       <td>ThC</td>
                                       <td>2</td>
                                   </tr>
                                   <tr>
                                       <td>Sorc Mefera</td>
                                       <td>ThC-Chiterita</td>                               
                                       <td>Joan</td>
                                       <td>ThC</td>
                                       <td>2</td>
                                   </tr>
                                   <tr>
                                       <td>Multiclass</td>
                                       <td>Javipastore</td>                               
                                       <td>Leo</td>
                                       <td>No tiene</td>
                                       <td>1</td>
                                   </tr>
                                                                      <tr>
                                       <td>X</td>
                                       <td>X</td>                               
                                       <td>X</td>
                                       <td>X</td>
                                       <td>X</td>
                                   </tr>
                               </tbody>
                           </table>
                       </div>`
            },
            


            {
                name: 'MvP - PvM',
                text: `
        <!-- Contenedor principal -->
        <div style="display: flex; flex-direction: column; align-items: center; margin: 20px;">
        
            <!-- Tabla superior con margen ajustado -->
            <div class="pricing-table" style="width: 100%; max-width: 650px;">
        <table style="width: 100%; border-collapse: collapse; overflow-x: auto;">
            <thead>
                <tr>
                    <th style="text-align: center; font-size: 1.3em; padding: 10px; background-color: #444;">Mejores Jugadas en Torneos Oficiales:</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ThC-Akame (Joan) 2 vs 1 sin flechas</td>
                </tr>
            </tbody>
        </table>
        
        
            <!-- Contenedor para los videos -->
            <div style="margin: 20px 0; text-align: center; width: 100%; max-width: 800px; display: flex; flex-direction: column; align-items: center; gap: 20px;">
                <video 
                    src="${JoanVid}" 
                    alt="vid1"
                    controls 
                    loop 
                    muted
                    autoplay
                    class="responsive-video"
                    style="width: 100%; max-width: 800px; border-radius: 15px;" 
                >
                    Tu navegador no soporta la etiqueta de video.
                </video>

        </div>
        
            </div>
        </div>
        
                    
                `
            },


            { 
                name: 'Nivel 99 Sc/Hc', 
                text: `<div class="cups-table">
                           <table>
                               <tbody>
                                   <tr>
                                       <th style="text-align: center; font-size: 1.3em;">
                               Personajes Nivel 99:
                             
                                   </tr>
                                   <tr>
                                       <th>Personaje:</th>
                                       <th>Clase:</th>
                                       <th>Nombre:</th>
                                       <th>Sc/Hc:</th>
                                       <th>Temporada:</th>
                                   </tr>
                                                                                                         <tr>
                                       <td>HDS-DuaLipa</td>
                                       <td>Sorc</td>                               
                                       <td>Diego</td>
                                       <td>Sc</td>
                                       <td>3</td>
                                   </tr>
                                                                      <tr>
                                       <td>Dua-Lipa</td>
                                       <td>Sorc</td>                               
                                       <td>Diego</td>
                                       <td>Sc</td>
                                       <td>2</td>
                                   </tr>
                                   <tr>
                                       <td>Masha</td>
                                       <td>Sorc</td>                               
                                       <td>Leo</td>
                                       <td>Sc</td>
                                       <td>1</td>
                                   </tr>
                                </tbody>
                            </table>
                        </div>`
                

            },     
    ]
}

    const toggleDropdown = (option) => {
        setDropdowns(prevState => ({
            ...prevState,
            [option]: !prevState[option],
        }));
    };

    

    const closeDropdowns = () => {
        setDropdowns({
            "Cambios Generales": false,
            "Cofre De Items": false,
            "Bolsa de Runas": false,
        });
    };

    const handleSubOptionClick = (text) => {
        setSelectedText(text);
        closeDropdowns();
    };


    
    return (
        
        <div className="subweb-container">
            <div className="subweb-navbar">
                <div 
                    className="subweb-logo" 
                    onClick={() => window.location.href = '/'}
                >
                    <img src={logo} alt="Logo" className="subweb-logo-img" />
                </div>
                {Object.keys(subOptions).map((option) => (
                    <div 
                        key={option} 
                        className="subweb-nav-option" 
                        onClick={() => toggleDropdown(option)}
                        onMouseEnter={() => toggleDropdown(option)}
                        onMouseLeave={closeDropdowns}
                    >
                        {option}
                        {dropdowns[option] && (
                            <div className="subweb-dropdown">
                                {subOptions[option].map((subOption) => (
                                    <div
                                        key={subOption.name}
                                        className="subweb-dropdown-item"
                                        onClick={() => handleSubOptionClick(subOption.text)}
                                    >
                                        {subOption.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
                                <div 
                    className="subweb-nav-option" 
                    onClick={() => handleOptionClick("Ir a Breakpoints")}
                >
                    Breakpoints
                </div>
                <div 
                    className="subweb-nav-option" 
                    onClick={() => handleOptionClick("Ir a Donaciones")}
                >
                    Donaciones
                </div>

                <div 
    className="subweb-nav-option" 
    onClick={() => handleOptionClick("Ir a Highlights")}  // Manejador para Highlights
>
    Highlights PvP
</div>
            </div> 
            <div id="subweb-text-display" className="subweb-text-display" dangerouslySetInnerHTML={{ __html: selectedText }} />
        </div>
        
    );
    
};



export default Cambios;
